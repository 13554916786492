import React, { Fragment } from 'react';
// import { Link } from 'gatsby';
import Layout from '../components/layoutProfile';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import mng from '../images/ob/kito.png';
import tobori from '../images/ob/yanagida0_2024.jpg';
import toboriImg1 from '../images/ob/yanagida1_2024.jpg';
import toboriImg2 from '../images/ob/yanagida2_2024.jpg';
// import * as ROUTES from '../src/constants/routes';

// <div className="ob-top-align">
// <img className="ob-top-info" alt="sakai" src={toboriTop} />
// </div>
class ManabePage extends React.Component {

  render() {
    return (
      <Fragment>
      <title>OBインタビュー　柳田大斗|國學院大學久我山高等学校　バスケットボール部</title>
        <h2 className="content-title-black-ob">OBインタビュー</h2>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
        <h4 className="vertical-timeline-element-subtitle">2年 鬼頭佑育</h4>
          <div className="mng-text">
本日は、お忙しい中、インタビューに応じて下さり、ありがとうございます。久我山高校バスケ部の鬼頭と申します。本日は、どうぞよろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <h4 className="vertical-timeline-element-subtitle">柳田大斗</h4>
          <div className="ob-text">
          よろしくお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: 'rgb(24, 23, 51)', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
            まず、自己紹介からお願いします！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          2021年度に國學院久我山高校を卒業しました、柳田大斗と申します。卒業後、法政大学の経営学部に進学し、現在は法政大学のバスケットボール部に所属しています。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
ありがとうございます。では、早速、國學院久我山への入学理由を教えて下さい。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          二つあって、一つは練習体験に行かせてもらった際に、先輩や酒井先生にとても優しくして頂いて、楽しかったからです。もう一つは、中学の東京都選抜で一緒だった宇津木と、小学校の同級生で、一緒にミニバスをやっていた中津と、もう一度３人で一緒にバスケがしたいと思ったからです。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。僕も初めて体験に行った時はとても緊張したのですが、酒井先生や先輩方が、とても優しくしてくださったのが印象的でした！では、次の質問に移らせていただきます。久我山は勉強においてもバスケットにおいても頑張る”文武両道”を実践していると思いますが、実際に文武両道を実践するにあたって何か意識していたことはありますか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          とにかく時間を浪費しないようにする事です。 部活も勉強も大変だった久我山高校の生活において、メリハリをつけることが重要だったと思います。「遊ばない」という事ではなく、通学時間や練習開始までの時間など、ちょっとした時間を浪費することなく、勉強につぎ込むことを意識していました。教科書を広げながら毎日、通学路を歩いていたのが思い出に残っています。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
「時間を無駄にしない」、「メリハリをつける」ということが大事なのですね！確かに勉強だけでなくバスケットボールにもそういう切り替えという部分は通じますね！では次に、久我山へ入学して良かったことを勉強面・部活面でお願いします！ </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          はい。勉強面では、勉強の本質を理解して、コツコツ取り組むことの大切さを知りました。小テストが週に1～2回あって、頑張れば１００点を取れるような内容だったので、成果を実感しやすい環境がありがたかったなと思います。 部活面では、何事にも考えて取り組む能力がついたなと思います。プレー1つ1つの動きもそうですし、限られた練習時間の活用の仕方もよく考えていました。 バスケとは関係ないんですけど、”どうやったら社会のごみを減らせるか”と言った、社会問題についても、同期の椎橋と考えていました（笑）
        </div>
        </VerticalTimelineElement>
        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obpageImg" alt="manabekei" src={toboriImg1} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          素晴らしいですね！國學院久我山高校には志が高い人たちが入学してくるので、僕の友達にもそう言った社会問題について取り組んでる人います！そういった志が高く持てるという面で、すごくいい環境に身を置けているなと実感します！では次に、國學院久我山はインサイドのチームで大きな選手がたくさん入ってくると思いますが、大斗さんもすごい身長がおっきい選手だと思うんですけど、センターとしてバスケットの面では久我山の魅力とはなんですか？
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          「自分が点を取らなければいけない」という責任感の中で、プレーできる事です。 他のチームではセンターはスクリーンをかけて、こぼれ球を拾う様な、どちらかと言うとアシストをするような役割を任せられているチームが多い中で、國學院久我山では“センターが点を取る”というコンセプトのセットプレーが多かったです。責任がある分、葛藤や重圧が大きかったですが、自分を強くしてくれた良い経験だったと思います。
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では、そんな中で酒井先生の当時の印象について教えてください！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          酒井先生の印象は、とにかく人に何かを与えたい、何かを感じてもらいたいというような考えを持っていると言う印象です。自分は酒井先生に将来の夢を与えてもらいました。今はその夢に向かって、邁進していますが、酒井先生に長期のインターン先を紹介していただき、卒業後も学びの場を提供していただいているので、とても感謝しています。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
        >
          <div className="ob-text">
          <img className="obpageImg" alt="manabekei" src={toboriImg2} />
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          酒井先生はただバスケットをやって強くなるのではなく、バスケットを通じてその先の将来のことまで考えてくださるという印象が僕もあります！
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          そうですね！
        </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          では次の質問です！大斗さんは法政大学へ進学したとおっしゃっていましたが、法政大学への入学理由を教えてください。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          僕が法政大学へ入学したきっかけは、久我山の先輩である長橋さんや岡部さんが法政大学バスケットボール部に在籍していて、とても楽しくバスケットボールをやれていると伺った事と、バスケットボール以外にも注力できる練習環境だったからです。部活動に参加しながら、インターンに参加してアプリ開発に携わったり、エクセルの技能を競う公式の大会で、全国入賞という成績を残すことが出来ました。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。では、最後に國學院久我山高校に入学希望のある中学生とその保護者に向けてメッセージをお願いします。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          國學院久我山高校は、部活と勉強でとても忙しく、正直つらいと感じることも多々あります。ですが、しっかりとした真面目な人間に成りたい、将来の事を考えた上で目の前のことに全力で取り組みたい方にはうってつけの高校だと思います。 自分自身、大学生になって、國學院久我山高校の環境で教育を受けることが出来て良かったと、つくづく感じていますので、軸のある学生生活を送りたい方に、是非入学していただければと思います。
        </div>
        </VerticalTimelineElement>


        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: '#eaeaea', color: '#fff', margin: '5rem, 0px, 0px, 0px' }}
          contentArrowStyle={{ borderRight: '7px solid  rgb(234, 234, 234)'}}
          icon={<img className="mng-logo" alt="sakai" src={mng} />}
        >
          <div className="mng-text">
          ありがとうございます。インタビューは以上です！本日はお忙しい中ありがとうございました。
          </div>
        </VerticalTimelineElement>

        <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'white', color: 'rgb(24, 23, 51)'}}
        contentArrowStyle={{ borderRight: '7px solid  white' }}
          icon={<img className="ob-logo" alt="sakai" src={tobori} />}
        >
          <div className="ob-text">
          ありがとうございました！
        </div>
        </VerticalTimelineElement>


      </VerticalTimeline>
    </Fragment>
);
}
}

export default () => (
  <Layout>
  <ManabePage />
  </Layout>
);
